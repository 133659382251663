/* GENERATED BY FIGMA PLUGIN. PLEASE DO NOT MODIFY */

:root {
  --color-accent-magenta-100: #ffe5fe;
  --color-accent-magenta-300: #ffa3fa;
  --color-accent-magenta-500: #fa52f1;
  --color-accent-magenta-700: #a60c9e;
  --color-accent-magenta-900: #4d0449;
  --color-accent-magenta-alpha-100: rgb(250 82 241 / 20%);
  --color-accent-magenta-alpha-200: rgb(250 82 241 / 40%);
  --color-accent-magenta-alpha-300: rgb(250 82 241 / 60%);
  --color-accent-magenta-alpha-400: rgb(250 82 241 / 80%);
  --color-accent-mustard-100: #fff3e5;
  --color-accent-mustard-300: #ffd19c;
  --color-accent-mustard-500: #fa9f34;
  --color-accent-mustard-700: #bf6c0a;
  --color-accent-mustard-900: #402200;
  --color-accent-mustard-alpha-100: rgb(250 159 52 / 20%);
  --color-accent-mustard-alpha-200: rgb(250 159 52 / 40%);
  --color-accent-mustard-alpha-300: rgb(250 159 52 / 60%);
  --color-accent-mustard-alpha-400: rgb(250 159 52 / 80%);
  --color-accent-rust-100: #ffede5;
  --color-accent-rust-300: #ffc3ab;
  --color-accent-rust-500: #ff6e36;
  --color-accent-rust-700: #b23b0c;
  --color-accent-rust-900: #4d1804;
  --color-accent-rust-alpha-100: rgb(255 110 54 / 20%);
  --color-accent-rust-alpha-200: rgb(255 110 54 / 40%);
  --color-accent-rust-alpha-300: rgb(255 110 54 / 60%);
  --color-accent-rust-alpha-400: rgb(255 110 54 / 80%);
  --color-neutral-100: #ebeaed;
  --color-neutral-200: #d6d4d9;
  --color-neutral-300: #bcb9bf;
  --color-neutral-400: #8c898f;
  --color-neutral-500: #6c696f;
  --color-neutral-50: #f9f9fa;
  --color-neutral-600: #525055;
  --color-neutral-700: #3a383c;
  --color-neutral-800: #232224;
  --color-neutral-900: #111012;
  --color-neutral-alpha-dark-100: rgb(108 105 111 / 20%);
  --color-neutral-alpha-dark-200: rgb(108 105 111 / 40%);
  --color-neutral-alpha-dark-300: rgb(108 105 111 / 60%);
  --color-neutral-alpha-dark-400: rgb(108 105 111 / 80%);
  --color-neutral-alpha-light-100: rgb(246 246 247 / 20%);
  --color-neutral-alpha-light-200: rgb(246 246 247 / 40%);
  --color-neutral-alpha-light-300: rgb(246 246 247 / 60%);
  --color-neutral-alpha-light-400: rgb(246 246 247 / 80%);
  --color-neutral-black: #000000;
  --color-neutral-white: #ffffff;
  --color-primary-grape-100: #f2e5ff;
  --color-primary-grape-200: #e3c9ff;
  --color-primary-grape-300: #d4aaff;
  --color-primary-grape-400: #bb7aff;
  --color-primary-grape-500: #a550ff;
  --color-primary-grape-50: #f9f2ff;
  --color-primary-grape-600: #9027ff;
  --color-primary-grape-700: #6310bb;
  --color-primary-grape-800: #420681;
  --color-primary-grape-900: #28044d;
  --color-primary-grape-alpha-100: rgb(165 80 255 / 20%);
  --color-primary-grape-alpha-200: rgb(165 80 255 / 40%);
  --color-primary-grape-alpha-300: rgb(165 80 255 / 60%);
  --color-primary-grape-alpha-400: rgb(165 80 255 / 80%);
  --color-primary-grape-alpha-50: rgb(165 80 255 / 10%);
  --color-support-danger-100: #fcdbdd;
  --color-support-danger-200: #fab1b6;
  --color-support-danger-300: #f7838a;
  --color-support-danger-400: #f55a63;
  --color-support-danger-500: #f2303c;
  --color-support-danger-50: #fff2f3;
  --color-support-danger-600: #d9212c;
  --color-support-danger-700: #ba111b;
  --color-support-danger-800: #7a060d;
  --color-support-danger-900: #4d0408;
  --color-support-danger-alpha-100: rgb(242 48 60 / 20%);
  --color-support-danger-alpha-200: rgb(242 48 60 / 40%);
  --color-support-danger-alpha-300: rgb(242 48 60 / 60%);
  --color-support-danger-alpha-400: rgb(242 48 60 / 80%);
  --color-support-danger-alpha-50: rgb(242 48 60 / 10%);
  --color-support-success-100: #d3f5dc;
  --color-support-success-200: #aff0c1;
  --color-support-success-300: #87e5a2;
  --color-support-success-400: #57d97c;
  --color-support-success-500: #2ec95a;
  --color-support-success-50: #edfaf1;
  --color-support-success-600: #1aab43;
  --color-support-success-700: #09872d;
  --color-support-success-800: #056e23;
  --color-support-success-900: #034d18;
  --color-support-success-alpha-100: rgb(46 201 90 / 20%);
  --color-support-success-alpha-200: rgb(46 201 90 / 40%);
  --color-support-success-alpha-300: rgb(46 201 90 / 60%);
  --color-support-success-alpha-400: rgb(46 201 90 / 80%);
  --color-support-success-alpha-50: rgb(46 201 90 / 10%);
  --color-support-warning-100: #faefd4;
  --color-support-warning-200: #fae6b4;
  --color-support-warning-300: #f7d98d;
  --color-support-warning-400: #f5ca5d;
  --color-support-warning-500: #e8b32e;
  --color-support-warning-50: #fcf7e8;
  --color-support-warning-600: #c9991e;
  --color-support-warning-700: #9e7408;
  --color-support-warning-800: #6e5003;
  --color-support-warning-900: #4d3804;
  --color-support-warning-alpha-100: rgb(232 179 46 / 20%);
  --color-support-warning-alpha-200: rgb(232 179 46 / 40%);
  --color-support-warning-alpha-300: rgb(232 179 46 / 60%);
  --color-support-warning-alpha-400: rgb(232 179 46 / 80%);
  --color-support-warning-alpha-50: rgb(232 179 46 / 10%);
  --radius-default: 6px;
  --radius-full: 9999px;
  --radius-minimal: 4px;
  --radius-none: 0px;
  --radius-rounded: 8px;
  --spacing-2xl: 40px;
  --spacing-3xl: 64px;
  --spacing-4xl: 80px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-none: 0px;
  --spacing-s: 12px;
  --spacing-xl: 32px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
  --color-bg-accent-1: var(--color-accent-magenta-500);
  --color-bg-accent-2: var(--color-accent-mustard-500);
  --color-bg-accent-3: var(--color-accent-rust-500);
  --color-bg-danger: var(--color-support-danger-50);
  --color-bg-default: var(--color-neutral-white);
  --color-bg-disabled: var(--color-neutral-100);
  --color-bg-elevated-default: var(--color-bg-default);
  --color-bg-elevated-hover: var(--color-bg-hover);
  --color-bg-emphasis-default: var(--color-neutral-900);
  --color-bg-emphasis-primary-subtle: var(--color-primary-grape-alpha-50);
  --color-bg-emphasis-primary: var(--color-primary-grape-500);
  --color-bg-hover: var(--color-neutral-100);
  --color-bg-subtle: var(--color-neutral-50);
  --color-bg-success: var(--color-support-success-50);
  --color-bg-warning: var(--color-support-warning-50);
  --color-border-danger: var(--color-support-danger-500);
  --color-border-default: var(--color-neutral-200);
  --color-border-muted: var(--color-neutral-100);
  --color-border-primary: var(--color-primary-grape-500);
  --color-border-success: var(--color-support-success-500);
  --color-border-warning: var(--color-support-warning-500);
  --color-button-primary-bg-default: var(--color-primary-grape-500);
  --color-button-primary-bg-disabled: var(--color-primary-grape-200);
  --color-button-primary-bg-hover: var(--color-primary-grape-600);
  --color-button-primary-fg-default: var(--color-neutral-white);
  --color-button-primary-fg-disabled: var(--color-button-primary-fg-default);
  --color-button-secondary-bg: var(--color-neutral-white);
  --color-button-secondary-border: var(--color-neutral-200);
  --color-button-secondary-fg: var(--color-neutral-900);
  --color-fg-accent-1: var(--color-accent-magenta-500);
  --color-fg-accent-2: var(--color-accent-mustard-500);
  --color-fg-accent-3: var(--color-accent-rust-500);
  --color-fg-danger: var(--color-support-danger-600);
  --color-fg-default: var(--color-neutral-black);
  --color-fg-muted: var(--color-neutral-400);
  --color-fg-onemphasis-default: var(--color-neutral-white);
  --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-400);
  --color-fg-primary: var(--color-primary-grape-600);
  --color-fg-subtle: var(--color-neutral-300);
  --color-fg-success: var(--color-support-success-600);
  --color-fg-warning: var(--color-support-warning-600);
  --color-input-bg: var(--color-neutral-white);
  --color-input-border: var(--color-border-default);
  --color-input-fg-input: var(--color-fg-default);
  --color-input-fg-placeholder: var(--color-fg-muted);
  --color-timeline-firing-bg-default: var(--color-support-danger-100);
  --color-timeline-firing-bg-hover: var(--color-support-danger-200);
  --color-timeline-firing-fg: var(--color-fg-danger);
  --color-timeline-pending-bg-default: var(--color-support-warning-100);
  --color-timeline-pending-bg-hover: var(--color-support-warning-200);
  --color-timeline-pending-fg: var(--color-support-warning-700);
  --font-body-l-medium: 500 16px / 24px Inter;
  --font-body-l-regular: 400 16px / 24px Inter;
  --font-body-md-medium: 500 14px / 24px Inter;
  --font-body-md-regular: 400 14px / 24px Inter;
  --font-body-sm-medium: 500 12px / 20px Inter;
  --font-body-sm-regular: 400 12px / 20px Inter;
  --font-body-xl-medium: 500 20px / 32px Inter;
  --font-body-xl-regular: 400 20px / 32px Inter;
  --font-body-xs-medium: 500 10px / 16px Inter;
  --font-body-xs-regular: 400 10px / 16px Inter;
  --font-buttons-md: 600 14px / 16px Basier Square;
  --font-buttons-sm: 600 12px / 16px Basier Square;
  --font-code-medium: 500 16px / 20px Inconsolata;
  --font-code-regular: 400 16px / 20px Inconsolata;
  --font-display-1: 700 34px / 40px Basier Square;
  --font-headings-h1: 500 30px / 40px Basier Square;
  --font-headings-h2: 500 24px / 32px Basier Square;
  --font-headings-h3: 500 20px / 24px Basier Square;
  --font-headings-h4: 500 16px / 20px Basier Square;
  --font-headings-h5: 500 14px / 16px Basier Square;
  --font-headings-h6: 500 12px / 16px Basier Square;
  --focus-primary: 0px 0px 0px 4px rgb(165 80 255 / 20%);
  --shadow-m: 0px 8px 4px 0px rgb(0 0 0 / 10%), 0px 0px 2px 0px rgb(0 0 0 / 5%);
  --shadow-xxs: 0px 1px 2px 0px rgb(0 0 0 / 5%);
}

body[data-theme="dark"] {
  --color-bg-accent-1: var(--color-accent-magenta-alpha-300);
  --color-bg-accent-2: var(--color-accent-mustard-alpha-300);
  --color-bg-accent-3: var(--color-accent-rust-alpha-300);
  --color-bg-danger: var(--color-support-danger-alpha-50);
  --color-bg-default: var(--color-neutral-900);
  --color-bg-disabled: var(--color-neutral-800);
  --color-bg-elevated-default: var(--color-neutral-700);
  --color-bg-elevated-hover: var(--color-neutral-600);
  --color-bg-emphasis-default: var(--color-neutral-700);
  --color-bg-emphasis-primary-subtle: var(--color-primary-grape-alpha-100);
  --color-bg-emphasis-primary: var(--color-primary-grape-alpha-300);
  --color-bg-hover: var(--color-neutral-700);
  --color-bg-subtle: var(--color-neutral-800);
  --color-bg-success: var(--color-support-success-alpha-50);
  --color-bg-warning: var(--color-support-warning-alpha-50);
  --color-border-danger: var(--color-support-danger-400);
  --color-border-default: var(--color-neutral-500);
  --color-border-muted: var(--color-neutral-700);
  --color-border-primary: var(--color-primary-grape-400);
  --color-border-success: var(--color-support-success-400);
  --color-border-warning: var(--color-support-warning-400);
  --color-button-primary-bg-default: var(--color-primary-grape-alpha-300);
  --color-button-primary-bg-disabled: var(--color-primary-grape-alpha-200);
  --color-button-primary-bg-hover: var(--color-primary-grape-500);
  --color-button-primary-fg-default: var(--color-neutral-white);
  --color-button-primary-fg-disabled: var(--color-neutral-alpha-light-200);
  --color-button-secondary-bg: var(--color-neutral-800);
  --color-button-secondary-border: var(--color-neutral-600);
  --color-button-secondary-fg: var(--color-neutral-white);
  --color-fg-accent-1: var(--color-accent-magenta-500);
  --color-fg-accent-2: var(--color-accent-mustard-500);
  --color-fg-accent-3: var(--color-accent-rust-500);
  --color-fg-danger: var(--color-support-danger-400);
  --color-fg-default: var(--color-neutral-white);
  --color-fg-muted: var(--color-neutral-300);
  --color-fg-onemphasis-default: var(--color-neutral-white);
  --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-400);
  --color-fg-primary: var(--color-primary-grape-400);
  --color-fg-subtle: var(--color-neutral-500);
  --color-fg-success: var(--color-support-success-400);
  --color-fg-warning: var(--color-support-warning-400);
  --color-input-bg: var(--color-neutral-800);
  --color-input-border: var(--color-border-default);
  --color-input-fg-input: var(--color-fg-default);
  --color-input-fg-placeholder: var(--color-fg-muted);
  --color-timeline-firing-bg-default: var(--color-support-danger-800);
  --color-timeline-firing-bg-hover: var(--color-support-danger-700);
  --color-timeline-firing-fg: var(--color-support-danger-300);
  --color-timeline-pending-bg-default: var(--color-support-warning-800);
  --color-timeline-pending-bg-hover: var(--color-support-warning-700);
  --color-timeline-pending-fg: var(--color-support-warning-300);
}

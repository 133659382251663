/*
  NOTE: to make CSS container queries work they need to be defined in a .css
  file and imported in a React component. We can't use CSS modules here because
  the container query only works in a global scope.
 */

/* Uses the same breakpoints & values coming from the themed media queries */
@supports (container-type: inline-size) {
  .functionPageContainerChild {
    /* Initial values coming from src/theme/spacing.ts */
    --spacing-page-inline: 12px;
    --spacing-page-block: 20px 40px;
    --spacing-page-gap: 24px;
    --spacing-content-gap-md: 12px;
    --spacing-content-gap-lg: 16px;
    --spacing-content-template-columns: 1fr;

    --flex-direction-name-last-updated: column;
    --width-tab-list: auto;
  }

  @container functionPageContainer (min-width: 512px) {
    .functionPageContainerChild {
      --spacing-page-inline: 20px;
      --spacing-page-block: 40px 80px;
      --spacing-content-template-columns: repeat(2, 1fr);

      --width-tab-list: fit-content;
    }
  }

  @container functionPageContainer (min-width: 768px) {
    .functionPageContainerChild {
      --spacing-page-inline: 40px;
      --spacing-page-gap: 32px;
      --spacing-content-gap-md: 16px;
      --spacing-content-gap-lg: 20px;

      --flex-direction-name-last-updated: row;
    }
  }

  @container functionPageContainer (min-width: 960px) {
    .functionPageContainerChild {
      --spacing-content-template-columns: repeat(3, 1fr);
    }
  }
}

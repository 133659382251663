/* inter-regular - latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./inter-v3-latin-ext-regular.woff2") format("woff2"),
    url("./inter-v3-latin-ext-regular.woff") format("woff");
  font-display: swap;
}

/* inter-500 - latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./inter-v3-latin-ext-500.woff2") format("woff2"),
    url("./inter-v3-latin-ext-500.woff") format("woff");
  font-display: swap;
}

/* inter-600 - latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./inter-v3-latin-ext-600.woff2") format("woff2"),
    url("./inter-v3-latin-ext-600.woff") format("woff");
  font-display: swap;
}

/* inter-700 - latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./inter-v3-latin-ext-700.woff2") format("woff2"),
    url("./inter-v3-latin-ext-700.woff") format("woff");
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./roboto-v27-latin-700.woff2") format("woff2"),
    url("./roboto-v27-latin-700.woff") format("woff");
  font-display: swap;
}

/* dm-mono-regular - latin-ext */
@font-face {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./dm-mono-v5-latin-ext-regular.woff2") format("woff2"),
    url("./dm-mono-v5-latin-ext-regular.woff") format("woff");
  font-display: swap;
}

/* dm-mono-500 - latin-ext */
@font-face {
  font-family: "DM Mono";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./dm-mono-v5-latin-ext-500.woff2") format("woff2"),
    url("./dm-mono-v5-latin-ext-500.woff") format("woff");
  font-display: swap;
}

/* inter-600 - latin-ext */
@font-face {
  font-family: "Alliance";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./Alliance-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Literata";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./Literata-VariableFont.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./Inconsolata-VariableFont.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./Inconsolata-VariableFont.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Basier Square";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./BasierSquare-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Basier Square";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./BasierSquare-Medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Basier Square";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./BasierSquare-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Basier Square";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./BasierSquare-Bold.woff2") format("woff2");
  font-display: swap;
}

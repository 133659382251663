@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/inter-v3-latin-ext-regular.ef974517.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-regular.ac41b38a.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/inter-v3-latin-ext-500.c72839d9.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-500.1c9c3f79.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/inter-v3-latin-ext-600.e84b862c.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-600.a4d12afc.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/inter-v3-latin-ext-700.4ef09ca6.woff2") format("woff2"), url("../fonts/inter-v3-latin-ext-700.11d55ccc.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v27-latin-700.40de70ad.woff2") format("woff2"), url("../fonts/roboto-v27-latin-700.2a7a95d3.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/dm-mono-v5-latin-ext-regular.ee460864.woff2") format("woff2"), url("../fonts/dm-mono-v5-latin-ext-regular.0c115929.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: DM Mono;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/dm-mono-v5-latin-ext-500.ee966a3f.woff2") format("woff2"), url("../fonts/dm-mono-v5-latin-ext-500.a4b84b3e.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: Alliance;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/Alliance-SemiBold.f50d1d29.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Literata;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Literata-VariableFont.8607a2c9.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/Inconsolata-VariableFont.a2e90248.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/Inconsolata-VariableFont.a2e90248.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Basier Square;
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/BasierSquare-Regular.a769d8eb.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Basier Square;
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/BasierSquare-Medium.9c228ea8.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Basier Square;
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../fonts/BasierSquare-SemiBold.335432f4.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: Basier Square;
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/BasierSquare-Bold.60c0c3b7.woff2") format("woff2");
  font-display: swap;
}

:root {
  --color-accent-magenta-100: #ffe5fe;
  --color-accent-magenta-300: #ffa3fa;
  --color-accent-magenta-500: #fa52f1;
  --color-accent-magenta-700: #a60c9e;
  --color-accent-magenta-900: #4d0449;
  --color-accent-magenta-alpha-100: #fa52f133;
  --color-accent-magenta-alpha-200: #fa52f166;
  --color-accent-magenta-alpha-300: #fa52f199;
  --color-accent-magenta-alpha-400: #fa52f1cc;
  --color-accent-mustard-100: #fff3e5;
  --color-accent-mustard-300: #ffd19c;
  --color-accent-mustard-500: #fa9f34;
  --color-accent-mustard-700: #bf6c0a;
  --color-accent-mustard-900: #402200;
  --color-accent-mustard-alpha-100: #fa9f3433;
  --color-accent-mustard-alpha-200: #fa9f3466;
  --color-accent-mustard-alpha-300: #fa9f3499;
  --color-accent-mustard-alpha-400: #fa9f34cc;
  --color-accent-rust-100: #ffede5;
  --color-accent-rust-300: #ffc3ab;
  --color-accent-rust-500: #ff6e36;
  --color-accent-rust-700: #b23b0c;
  --color-accent-rust-900: #4d1804;
  --color-accent-rust-alpha-100: #ff6e3633;
  --color-accent-rust-alpha-200: #ff6e3666;
  --color-accent-rust-alpha-300: #ff6e3699;
  --color-accent-rust-alpha-400: #ff6e36cc;
  --color-neutral-100: #ebeaed;
  --color-neutral-200: #d6d4d9;
  --color-neutral-300: #bcb9bf;
  --color-neutral-400: #8c898f;
  --color-neutral-500: #6c696f;
  --color-neutral-50: #f9f9fa;
  --color-neutral-600: #525055;
  --color-neutral-700: #3a383c;
  --color-neutral-800: #232224;
  --color-neutral-900: #111012;
  --color-neutral-alpha-dark-100: #6c696f33;
  --color-neutral-alpha-dark-200: #6c696f66;
  --color-neutral-alpha-dark-300: #6c696f99;
  --color-neutral-alpha-dark-400: #6c696fcc;
  --color-neutral-alpha-light-100: #f6f6f733;
  --color-neutral-alpha-light-200: #f6f6f766;
  --color-neutral-alpha-light-300: #f6f6f799;
  --color-neutral-alpha-light-400: #f6f6f7cc;
  --color-neutral-black: #000;
  --color-neutral-white: #fff;
  --color-primary-grape-100: #f2e5ff;
  --color-primary-grape-200: #e3c9ff;
  --color-primary-grape-300: #d4aaff;
  --color-primary-grape-400: #bb7aff;
  --color-primary-grape-500: #a550ff;
  --color-primary-grape-50: #f9f2ff;
  --color-primary-grape-600: #9027ff;
  --color-primary-grape-700: #6310bb;
  --color-primary-grape-800: #420681;
  --color-primary-grape-900: #28044d;
  --color-primary-grape-alpha-100: #a550ff33;
  --color-primary-grape-alpha-200: #a550ff66;
  --color-primary-grape-alpha-300: #a550ff99;
  --color-primary-grape-alpha-400: #a550ffcc;
  --color-primary-grape-alpha-50: #a550ff1a;
  --color-support-danger-100: #fcdbdd;
  --color-support-danger-200: #fab1b6;
  --color-support-danger-300: #f7838a;
  --color-support-danger-400: #f55a63;
  --color-support-danger-500: #f2303c;
  --color-support-danger-50: #fff2f3;
  --color-support-danger-600: #d9212c;
  --color-support-danger-700: #ba111b;
  --color-support-danger-800: #7a060d;
  --color-support-danger-900: #4d0408;
  --color-support-danger-alpha-100: #f2303c33;
  --color-support-danger-alpha-200: #f2303c66;
  --color-support-danger-alpha-300: #f2303c99;
  --color-support-danger-alpha-400: #f2303ccc;
  --color-support-danger-alpha-50: #f2303c1a;
  --color-support-success-100: #d3f5dc;
  --color-support-success-200: #aff0c1;
  --color-support-success-300: #87e5a2;
  --color-support-success-400: #57d97c;
  --color-support-success-500: #2ec95a;
  --color-support-success-50: #edfaf1;
  --color-support-success-600: #1aab43;
  --color-support-success-700: #09872d;
  --color-support-success-800: #056e23;
  --color-support-success-900: #034d18;
  --color-support-success-alpha-100: #2ec95a33;
  --color-support-success-alpha-200: #2ec95a66;
  --color-support-success-alpha-300: #2ec95a99;
  --color-support-success-alpha-400: #2ec95acc;
  --color-support-success-alpha-50: #2ec95a1a;
  --color-support-warning-100: #faefd4;
  --color-support-warning-200: #fae6b4;
  --color-support-warning-300: #f7d98d;
  --color-support-warning-400: #f5ca5d;
  --color-support-warning-500: #e8b32e;
  --color-support-warning-50: #fcf7e8;
  --color-support-warning-600: #c9991e;
  --color-support-warning-700: #9e7408;
  --color-support-warning-800: #6e5003;
  --color-support-warning-900: #4d3804;
  --color-support-warning-alpha-100: #e8b32e33;
  --color-support-warning-alpha-200: #e8b32e66;
  --color-support-warning-alpha-300: #e8b32e99;
  --color-support-warning-alpha-400: #e8b32ecc;
  --color-support-warning-alpha-50: #e8b32e1a;
  --radius-default: 6px;
  --radius-full: 9999px;
  --radius-minimal: 4px;
  --radius-none: 0px;
  --radius-rounded: 8px;
  --spacing-2xl: 40px;
  --spacing-3xl: 64px;
  --spacing-4xl: 80px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-none: 0px;
  --spacing-s: 12px;
  --spacing-xl: 32px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
  --color-bg-accent-1: var(--color-accent-magenta-500);
  --color-bg-accent-2: var(--color-accent-mustard-500);
  --color-bg-accent-3: var(--color-accent-rust-500);
  --color-bg-danger: var(--color-support-danger-50);
  --color-bg-default: var(--color-neutral-white);
  --color-bg-disabled: var(--color-neutral-100);
  --color-bg-elevated-default: var(--color-bg-default);
  --color-bg-elevated-hover: var(--color-bg-hover);
  --color-bg-emphasis-default: var(--color-neutral-900);
  --color-bg-emphasis-primary-subtle: var(--color-primary-grape-alpha-50);
  --color-bg-emphasis-primary: var(--color-primary-grape-500);
  --color-bg-hover: var(--color-neutral-100);
  --color-bg-subtle: var(--color-neutral-50);
  --color-bg-success: var(--color-support-success-50);
  --color-bg-warning: var(--color-support-warning-50);
  --color-border-danger: var(--color-support-danger-500);
  --color-border-default: var(--color-neutral-200);
  --color-border-muted: var(--color-neutral-100);
  --color-border-primary: var(--color-primary-grape-500);
  --color-border-success: var(--color-support-success-500);
  --color-border-warning: var(--color-support-warning-500);
  --color-button-primary-bg-default: var(--color-primary-grape-500);
  --color-button-primary-bg-disabled: var(--color-primary-grape-200);
  --color-button-primary-bg-hover: var(--color-primary-grape-600);
  --color-button-primary-fg-default: var(--color-neutral-white);
  --color-button-primary-fg-disabled: var(--color-button-primary-fg-default);
  --color-button-secondary-bg: var(--color-neutral-white);
  --color-button-secondary-border: var(--color-neutral-200);
  --color-button-secondary-fg: var(--color-neutral-900);
  --color-fg-accent-1: var(--color-accent-magenta-500);
  --color-fg-accent-2: var(--color-accent-mustard-500);
  --color-fg-accent-3: var(--color-accent-rust-500);
  --color-fg-danger: var(--color-support-danger-600);
  --color-fg-default: var(--color-neutral-black);
  --color-fg-muted: var(--color-neutral-400);
  --color-fg-onemphasis-default: var(--color-neutral-white);
  --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-400);
  --color-fg-primary: var(--color-primary-grape-600);
  --color-fg-subtle: var(--color-neutral-300);
  --color-fg-success: var(--color-support-success-600);
  --color-fg-warning: var(--color-support-warning-600);
  --color-input-bg: var(--color-neutral-white);
  --color-input-border: var(--color-border-default);
  --color-input-fg-input: var(--color-fg-default);
  --color-input-fg-placeholder: var(--color-fg-muted);
  --color-timeline-firing-bg-default: var(--color-support-danger-100);
  --color-timeline-firing-bg-hover: var(--color-support-danger-200);
  --color-timeline-firing-fg: var(--color-fg-danger);
  --color-timeline-pending-bg-default: var(--color-support-warning-100);
  --color-timeline-pending-bg-hover: var(--color-support-warning-200);
  --color-timeline-pending-fg: var(--color-support-warning-700);
  --font-body-l-medium: 500 16px / 24px Inter;
  --font-body-l-regular: 400 16px / 24px Inter;
  --font-body-md-medium: 500 14px / 24px Inter;
  --font-body-md-regular: 400 14px / 24px Inter;
  --font-body-sm-medium: 500 12px / 20px Inter;
  --font-body-sm-regular: 400 12px / 20px Inter;
  --font-body-xl-medium: 500 20px / 32px Inter;
  --font-body-xl-regular: 400 20px / 32px Inter;
  --font-body-xs-medium: 500 10px / 16px Inter;
  --font-body-xs-regular: 400 10px / 16px Inter;
  --font-buttons-md: 600 14px / 16px Basier Square;
  --font-buttons-sm: 600 12px / 16px Basier Square;
  --font-code-medium: 500 16px / 20px Inconsolata;
  --font-code-regular: 400 16px / 20px Inconsolata;
  --font-display-1: 700 34px / 40px Basier Square;
  --font-headings-h1: 500 30px / 40px Basier Square;
  --font-headings-h2: 500 24px / 32px Basier Square;
  --font-headings-h3: 500 20px / 24px Basier Square;
  --font-headings-h4: 500 16px / 20px Basier Square;
  --font-headings-h5: 500 14px / 16px Basier Square;
  --font-headings-h6: 500 12px / 16px Basier Square;
  --focus-primary: 0px 0px 0px 4px #a550ff33;
  --shadow-m: 0px 8px 4px 0px #0000001a, 0px 0px 2px 0px #0000000d;
  --shadow-xxs: 0px 1px 2px 0px #0000000d;
}

body[data-theme="dark"] {
  --color-bg-accent-1: var(--color-accent-magenta-alpha-300);
  --color-bg-accent-2: var(--color-accent-mustard-alpha-300);
  --color-bg-accent-3: var(--color-accent-rust-alpha-300);
  --color-bg-danger: var(--color-support-danger-alpha-50);
  --color-bg-default: var(--color-neutral-900);
  --color-bg-disabled: var(--color-neutral-800);
  --color-bg-elevated-default: var(--color-neutral-700);
  --color-bg-elevated-hover: var(--color-neutral-600);
  --color-bg-emphasis-default: var(--color-neutral-700);
  --color-bg-emphasis-primary-subtle: var(--color-primary-grape-alpha-100);
  --color-bg-emphasis-primary: var(--color-primary-grape-alpha-300);
  --color-bg-hover: var(--color-neutral-700);
  --color-bg-subtle: var(--color-neutral-800);
  --color-bg-success: var(--color-support-success-alpha-50);
  --color-bg-warning: var(--color-support-warning-alpha-50);
  --color-border-danger: var(--color-support-danger-400);
  --color-border-default: var(--color-neutral-500);
  --color-border-muted: var(--color-neutral-700);
  --color-border-primary: var(--color-primary-grape-400);
  --color-border-success: var(--color-support-success-400);
  --color-border-warning: var(--color-support-warning-400);
  --color-button-primary-bg-default: var(--color-primary-grape-alpha-300);
  --color-button-primary-bg-disabled: var(--color-primary-grape-alpha-200);
  --color-button-primary-bg-hover: var(--color-primary-grape-500);
  --color-button-primary-fg-default: var(--color-neutral-white);
  --color-button-primary-fg-disabled: var(--color-neutral-alpha-light-200);
  --color-button-secondary-bg: var(--color-neutral-800);
  --color-button-secondary-border: var(--color-neutral-600);
  --color-button-secondary-fg: var(--color-neutral-white);
  --color-fg-accent-1: var(--color-accent-magenta-500);
  --color-fg-accent-2: var(--color-accent-mustard-500);
  --color-fg-accent-3: var(--color-accent-rust-500);
  --color-fg-danger: var(--color-support-danger-400);
  --color-fg-default: var(--color-neutral-white);
  --color-fg-muted: var(--color-neutral-300);
  --color-fg-onemphasis-default: var(--color-neutral-white);
  --color-fg-onemphasis-subtle: var(--color-neutral-alpha-light-400);
  --color-fg-primary: var(--color-primary-grape-400);
  --color-fg-subtle: var(--color-neutral-500);
  --color-fg-success: var(--color-support-success-400);
  --color-fg-warning: var(--color-support-warning-400);
  --color-input-bg: var(--color-neutral-800);
  --color-input-border: var(--color-border-default);
  --color-input-fg-input: var(--color-fg-default);
  --color-input-fg-placeholder: var(--color-fg-muted);
  --color-timeline-firing-bg-default: var(--color-support-danger-800);
  --color-timeline-firing-bg-hover: var(--color-support-danger-700);
  --color-timeline-firing-fg: var(--color-support-danger-300);
  --color-timeline-pending-bg-default: var(--color-support-warning-800);
  --color-timeline-pending-bg-hover: var(--color-support-warning-700);
  --color-timeline-pending-fg: var(--color-support-warning-300);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

@supports (container-type: inline-size) {
  .functionPageContainerChild {
    --spacing-page-inline: 12px;
    --spacing-page-block: 20px 40px;
    --spacing-page-gap: 24px;
    --spacing-content-gap-md: 12px;
    --spacing-content-gap-lg: 16px;
    --spacing-content-template-columns: 1fr;
    --flex-direction-name-last-updated: column;
    --width-tab-list: auto;
  }

  @container functionPageContainer (width >= 512px) {
    .functionPageContainerChild {
      --spacing-page-inline: 20px;
      --spacing-page-block: 40px 80px;
      --spacing-content-template-columns: repeat(2, 1fr);
      --width-tab-list: fit-content;
    }
  }

  @container functionPageContainer (width >= 768px) {
    .functionPageContainerChild {
      --spacing-page-inline: 40px;
      --spacing-page-gap: 32px;
      --spacing-content-gap-md: 16px;
      --spacing-content-gap-lg: 20px;
      --flex-direction-name-last-updated: row;
    }
  }

  @container functionPageContainer (width >= 960px) {
    .functionPageContainerChild {
      --spacing-content-template-columns: repeat(3, 1fr);
    }
  }
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  padding: 0 3px 3px 0;
  position: absolute;
}

.react-resizable-handle-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.react-resizable-handle-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
  transform: rotate(270deg);
}

.react-resizable-handle-w, .react-resizable-handle-e {
  cursor: ew-resize;
  margin-top: -10px;
  top: 50%;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n, .react-resizable-handle-s {
  cursor: ns-resize;
  margin-left: -10px;
  left: 50%;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/*# sourceMappingURL=index.3527160e.css.map */
